import styled from '@emotion/styled'
import { ToggleContainer, ToggleOnContainer } from 'assets/images';

export const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  font-family: 'Barlow Semi Condensed';
  font-size: 14px;
  font-weight: bold;
  font-stretch: semi-condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  text-align: center;
  user-select: none;
  color: #242e42;
`;

export const ButtonContainer = styled.div`
  position: relative;
  width: 88px;
  height: 33px;
  background-image: url(${props => props.state ? ToggleOnContainer : ToggleContainer});
  background-size: 100% ${props => props.state ? '113%' : '100%'};
  display: flex;
  align-items: center;
  flex-direction: ${props => !props.state ? 'row' : 'row-reverse'};
  margin-top: 4px;
  border-radius: 14px;
  background-color: ${props => props.state ? '#4dd007' : '#666666'};
`;

export const ToggleClickable = styled.img`
  width: 37px;
  height: 37px;
  cursor: pointer;
  margin-top: 3px;
  margin-left: -1px;
  margin-right: -2px;
`;


export const Label = styled.div`
  // text-shadow: ${props => !props.state ? '0px 1px 0 #f94e4e' : '0px 1px 0 #b1ff81'};
  font-family: 'Barlow Semi Condensed Bold';
  font-size: 18px;
  font-weight: bold;
  font-stretch: semi-condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.9px;
  text-align: center;
  color: ${props => props.state ? '#2b3564' : '#000'};
  height: 33px;
  line-height: 31px;
  margin-right: ${props => props.state ? '8px' : 0};
  margin-left: ${props => !props.state ? '8px' : 0};
`;

export const CustomLabel = styled(Label)`
  display: flex;
  justify-content: center;
  margin-left:  ${props => !props.state ? '4px' : 0};
  color:  ${props => props.state ? '#2b3564' : '#333333'};
`